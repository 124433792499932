import * as http from '../../../../vue_shared/http'

interface IUser {
  id: number
  email?: string
  name_with_email?: string // eslint-disable-line camelcase
  name_or_email?: string // eslint-disable-line camelcase
  undeliverable_email?: boolean // eslint-disable-line camelcase
}

type UserType = 'requesters' | 'pocs' | 'staff'

interface IBaseOptions {
  search_text?: string // eslint-disable-line camelcase
  page_number?: number // eslint-disable-line camelcase
}

type OptionType = 'departments' | 'tags' | 'closure_responses'

interface IFetchOptions extends IBaseOptions {
  type: OptionType
}

interface IFetchUserOptions extends IBaseOptions {
  type: UserType
}

export function fetchUsersByType(options: IFetchUserOptions): Promise<IUser[]> {
  const { type, ...params } = Object.assign(
    {
      search_text: '',
      page_number: 1
    },
    options
  )

  return http.get<IUser[]>(`/client/requests/${type}`, params)
}

export function fetchUserByType(id: number, type: UserType): Promise<IUser> {
  return http.get<IUser>(`/client/requests/${type}/${id}`)
}

interface IOption {
  id: number
  name: string
}

export function fetchOptionsByType(options: IFetchOptions): Promise<IOption[]> {
  const { type, ...params } = options
  return http.get<IOption[]>(`/client/requests/${type}/`, params)
}

export function fetchOptionByType(
  id: number,
  type: OptionType
): Promise<IOption> {
  return http.get<IOption>(`/client/requests/${type}/${id}`)
}
