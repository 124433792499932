import API from '../vue_shared/api' // eslint-disable-line import/no-useless-path-segments

interface Account {
  civicplus_sso_feature_flag: boolean // eslint-disable-line camelcase
}

async function signOut(account: Account): Promise<void> {
  API.signOut().then(() => {
    localStorage.clear()

    if (account.civicplus_sso_feature_flag) {
      const ssoSignOutUrl = new URL(
        '/connect/endsession',
        process.env.CP_AUTH_URL
      )
      window.location.href = ssoSignOutUrl.href
    } else {
      window.location.reload()
    }
  })
}

export default signOut
