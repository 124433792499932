import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
  AxiosRequestConfig,
  AxiosHeaders
} from 'axios'
import { stringify } from './query_params'
import * as Sentry from '@sentry/browser'

enum ApiRequestMethodEnum {
  DELETE = 'delete',
  GET = 'get',
  POST = 'post',
  PUT = 'put'
}

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data?.redirect_to) {
      window.location.href = response.data.redirect_to
    }
    return response
  },
  (error: AxiosError) => Promise.reject(error)
)

axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    config.headers = config.headers || AxiosHeaders.from({})
    if (!config.headers['X-CSRF-Token']) {
      const csrfEl = document.getElementsByName('csrf-token')
      const token =
        csrfEl.length > 0 ? (csrfEl[0] as HTMLMetaElement).content : ''

      config.headers.set('X-CSRF-Token', token)
    }

    if (
      /\/requests$/i.test(config.url ?? '') &&
      config.method === ApiRequestMethodEnum.POST
    ) {
      config.data = {
        ...config.data,
        authenticity_token: config.headers['X-CSRF-Token']
      }
    }
    return config
  },
  (error: AxiosError) => Promise.reject(error)
)

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (
      error.response?.status === 403 &&
      typeof error.response.data === 'string' &&
      error.response.data.includes('InvalidAuthenticityToken')
    ) {
      const sentToken = error.config?.headers['X-CSRF-Token']
      const csrfEl = document.getElementsByName('csrf-token')
      const htmlToken =
        csrfEl.length > 0 ? csrfEl[0].getAttribute('content') : ``

      Sentry.captureMessage('Request failed with InvalidAuthenticityToken', {
        level: 'error',
        extra: {
          url: error.config?.url,
          method: error.config?.method,
          sentToken,
          htmlToken
        }
      })
    }
    return Promise.reject(error)
  }
)

function apiRequest<D, T>(
  method: ApiRequestMethodEnum,
  url: string,
  data?: D
): Promise<T> {
  return new Promise((resolve, reject) => {
    const config: AxiosRequestConfig = {
      method,
      url,
      data
    }

    axios(config)
      .then((response) => {
        resolve(response.data as T)
      })
      .catch(reject)
  })
}

export function remove<D>(url: string, data?: D): Promise<void> {
  return apiRequest<D, undefined>(ApiRequestMethodEnum.DELETE, url, data)
}

export function get<T>(url: string, data?: object): Promise<T> {
  if (data) {
    const queryParams = stringify(data)
    return apiRequest<undefined, T>(
      ApiRequestMethodEnum.GET,
      `${url}?${queryParams}`
    )
  } else {
    return apiRequest<undefined, T>(ApiRequestMethodEnum.GET, url)
  }
}

export function post<D, T>(url: string, data: D): Promise<T> {
  return apiRequest<D, T>(ApiRequestMethodEnum.POST, url, data)
}

export function put<D, T>(url: string, data: D): Promise<T> {
  return apiRequest<D, T>(ApiRequestMethodEnum.PUT, url, data)
}
