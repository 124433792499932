
import DOMPurify from 'dompurify'
import NrFormInput from '@nextrequestco/components/src/components/NrFormInput/nr-form-input.vue'
import NrIconButton from '@nextrequestco/components/src/components/NrIconButton/nr-icon-button.vue'
import NrModal from '@nextrequestco/components/src/components/NrModal/nr-modal.vue'
import NrIcon from '@nextrequestco/components/src/components/NrIcon/NrIcon.vue'
import InfiniteLoading from 'v3-infinite-loading'
import API from '../api'
import DocumentCard from '../../pages/documents/components/DocumentSearchResult.vue'
import { isEmpty } from 'lodash'

export default {
  components: {
    NrFormInput,
    NrIconButton,
    NrModal,
    NrIcon,
    DocumentCard,
    InfiniteLoading
  },
  data() {
    return {
      modalSearchTerm: '',
      searchTerm: '',
      openDocListModal: false,
      filteredDocuments: [],
      modalHeaderTitle: '',
      infiniteId: +new Date(),
      infinitePage: 1,
      infiniteState: {},
      pageSize: 50
    }
  },
  methods: {
    sanitize(content: string) {
      return DOMPurify.sanitize(content, { ALLOWED_TAGS: ['em'] })
    },

    triggerInfiniteHandler($state): void {
      this.infinitePage += 1
      this.infiniteState = $state
      const payload = {
        search_term: this.searchTerm,
        page_number: this.infinitePage,
        page_size: this.pageSize
      }
      API.fetchDocumentList(payload)
        .then((response) => {
          const documents = response.data.documents
          if (documents.length < this.pageSize) {
            $state.complete()
          } else {
            $state.loaded()
          }
          if (documents.length) {
            this.filteredDocuments = this.filteredDocuments.concat(documents)
          }
        })
        .catch(() => {
          this.$toasted.error('An error occured. Please try again.')
        })
    },
    submitFullTextSearch(): void {
      if (!this.searchTerm) return
      this.infinitePage = 1
      const payload = {
        search_term: this.searchTerm,
        page_number: this.infinitePage,
        page_size: this.pageSize
      }
      this.modalHeaderTitle = `Loading search results for "${this.searchTerm}"`
      this.openDocListModal = true
      API.fetchDocumentList(payload)
        .then((response) => {
          const documents = response.data.documents
          const totalCount = response.data.total_count
          if (documents.length <= this.pageSize) {
            this.filteredDocuments = documents
            if (!isEmpty(this.infiniteState)) {
              this.infiniteId = this.infiniteId + 1
            }
          } else {
            this.filteredDocuments = []
            if (!isEmpty(this.infiniteState)) {
              this.infiniteState.complete()
            }
          }
          this.modalSearchTerm = this.searchTerm
          this.modalHeaderTitle = `${totalCount} Search results for "${this.modalSearchTerm}"`
        })
        .catch(() => {
          this.$toasted.error('An error occured. Please try again.')
        })
    }
  }
}
