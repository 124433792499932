
export default {
  props: {
    header: {
      type: String
    },
    filetype: {
      type: String
    },
    visibility: {
      type: String
    },
    title: {
      type: String
    },
    url: {
      type: String
    },
    content: {
      type: String
    }
  },
  computed: {
    setVisibilityText() {
      return this.visibility === 'staff' ? 'Staff Only' : this.visibility
    }
  }
}
