
import { PropType } from 'vue'
import { truncate, throttle, uniqBy } from 'lodash'
import GlobalFullTextSearch from './global_full_text_search.vue'
import NrModal from '@nextrequestco/components/src/components/NrModal/nr-modal.vue'
import NrButton from '@nextrequestco/components/src/components/NrButton/nr-button'
import NrIcon from '@nextrequestco/components/src/components/NrIcon/NrIcon.vue'
import NrTooltip from '@nextrequestco/components/src/components/NrTooltip/NrTooltip.vue'
import NrSelect from '@nextrequestco/components/src/components/NrSelect/nr-select.vue'
import API from '../api.js'
import { mapMutations } from 'vuex'

const genericUsers = [
  {
    value: 'admin',
    label: 'Generic Admin',
    role: 'admin'
  },
  {
    value: 'publisher',
    label: 'Generic Publisher',
    role: 'publisher'
  },
  {
    value: 'staff',
    label: 'Generic Staff',
    role: 'staff'
  },
  {
    value: 'guest',
    label: 'Generic Requester',
    role: 'guest'
  }
]

type Account = {
  id: number
  name: string
  document_review: boolean // eslint-disable-line camelcase
  payments: boolean
  redaction: boolean
  uploaders_feature_flag: boolean // eslint-disable-line camelcase
  full_text_search_feature_flag: boolean // eslint-disable-line camelcase
  civicplus_sso_feature_flag: boolean // eslint-disable-line camelcase
}

type User = {
  id: number
  email_or_name: string // eslint-disable-line camelcase
}

export default {
  name: 'app-header',
  components: {
    GlobalFullTextSearch,
    NrModal,
    NrButton,
    NrIcon,
    NrTooltip,
    NrSelect
  },
  props: {
    account: {
      type: Object as PropType<Account | null>,
      default() {
        return {
          id: -1,
          name: 'NextRequest',
          document_review: false,
          payments: false,
          redaction: false,
          uploaders_feature_flag: false,
          full_text_search_feature_flag: false,
          civicplus_sso_feature_flag: false
        }
      }
    },
    user: {
      type: Object as PropType<User | null>,
      default: null
    },
    canManageInternalAdminTools: {
      type: Boolean,
      default: false
    },
    canReadStaffOnly: {
      type: Boolean,
      default: false
    },
    canManageAll: {
      type: Boolean,
      default: false
    },
    canReadAdminOnly: {
      type: Boolean,
      default: false
    },
    canPreviewUser: {
      type: Boolean,
      default: false
    },
    permissionsPreviewUser: {
      type: String,
      default: null
    },
    requestPrettyId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isMenuToggled: false,
      permissionModal: false,
      selectedPreviewUsers: [],
      filteredPreviewUsers: [],
      sessionExpired: false,
      previewFetchState: {
        page: 0,
        search_text: '',
        end: false,
        fetching: false
      },
      showSessionWarningModal: false,
      expirationInMinsAndSecs: '',
      csrfToken: null
    }
  },
  mounted() {
    this.setUpSessionExpiration()
    this.setCsrfTokenFromPage()
  },
  computed: {
    isRapidReview() {
      return this.$route.name === 'rapid-review'
    },

    isSpa(): boolean {
      return !!this.$router.options.routes.length
    },

    accountSettingsPath() {
      return `/admin/accounts/${this.account.id}`
    },

    userSettingsPath() {
      return `/settings?id=${this.user.id}`
    },

    accountName(): string {
      if (this.account) {
        return truncate(this.account.name, { length: 40 })
      } else {
        return 'NextRequest'
      }
    },

    isAuthenticated(): boolean {
      return !!this.user && !!this.account
    },

    hasFullTextFeature(): boolean {
      return this.isAuthenticated && this.account.full_text_search_feature_flag
    },
    selectedPreviewUserId(): number | null {
      return this.selectedPreviewUsers ? this.selectedPreviewUsers.value : null
    },
    hasCivicplusSSOFeature(): boolean {
      return this.account.civicplus_sso_feature_flag
    },
    csrfParam(): string {
      const csrfEl = document.getElementsByName('csrf-param')
      const param =
        csrfEl.length > 0 ? (csrfEl[0] as HTMLMetaElement).content : ``
      return param
    }
  },

  methods: {
    setCsrfTokenFromPage(): string {
      const csrfEl = document.getElementsByName('csrf-token')
      const token =
        csrfEl.length > 0 ? (csrfEl[0] as HTMLMetaElement).content : ``
      this.csrfToken = token
    },
    setCsrfTokenFromApi(): string {
      API.getCsrfToken().then((response) => {
        this.csrfToken = response.data.csrf_token
      })
    },
    resetSessionTimeout() {
      API.resetSession().then(() => {
        this.showSessionWarningModal = false
      })
    },
    timeToSessionExpiration() {
      let expirationTime = this.$cookies.get('nextrequest_session_expires')
      const now = new Date()
      expirationTime = new Date(expirationTime)
      const differenceTime = new Date(Math.abs(now - expirationTime))
      const minutes = differenceTime.getMinutes()
      const seconds = differenceTime.getSeconds()
      return { minutes: minutes, seconds: seconds }
    },
    setUpSessionExpiration() {
      const sessionCookiePresent = () =>
        this.$cookies?.isKey('nextrequest_session_expires')

      // If the cookie is not set then the session has no expiration, and so we do not need to check for it.
      if (sessionCookiePresent()) {
        const sessionExpirationInterval = setInterval(() => {
          if (this.user && !sessionCookiePresent()) {
            this.sessionExpired = true
            this.showSessionWarningModal = false
            this.setCsrfTokenFromApi()
            localStorage.clear()
            clearInterval(sessionExpirationInterval)
          } else if (this.user && sessionCookiePresent()) {
            const time = this.timeToSessionExpiration()
            if (time.minutes < 2) {
              const formatSeconds = time.seconds.toString().padStart(2, '0')
              this.expirationInMinsAndSecs = `${time.minutes} min ${formatSeconds} sec`
              this.showSessionWarningModal = true
            } else {
              this.showSessionWarningModal = false
            }
          }
        }, 1000)
      }
    },
    directToSignIn() {
      if (this.hasCivicplusSSOFeature) {
        this.$refs.signInForm.requestSubmit()
      } else {
        window.location.href = '/users/sign_in'
      }
    },
    handleNavigate(event, routerNavFunc) {
      if (this.isSpa) {
        return routerNavFunc(event)
      }
    },
    getNavbarItemClassName(path) {
      const isActive = window.location.pathname === path.path
      return ['navbar-item', { 'navbar-item--active': isActive }]
    },
    ...mapMutations(['setAbility']),
    submitPermission(): void {
      if (this.isRapidReview && this.selectedPreviewUsers.role === 'guest') {
        this.$toasted.error(
          'The user you have selected does not have access to this page'
        )
        return
      }
      API.setPermissionUserPreview({
        user_id: this.selectedPreviewUserId
      }).then((_response) => {
        window.location.reload()
      })
    },
    setPermissionModal(): void {
      this.permissionModal = true
    },
    searchPreviewUsers: throttle(function (searchText = '') {
      this.fetchPreviewUsers(searchText)
    }, 400),

    setPreviewUsers(staff: object): void {
      this.selectedPreviewUsers = staff
      console.log('setting preview!')
    },
    fetchPreviewUsers(searchText: string = ''): void {
      const newFetchState = { ...this.previewFetchState }
      if (
        this.previewFetchState.search_text === searchText && // eslint-disable-line camelcase
        this.previewFetchState.end
      ) {
        return
      }
      if (this.previewFetchState.search_text === searchText) {
        Object.assign(newFetchState, {
          search_text: searchText,
          page: this.previewFetchState.page + 1,
          fetching: true
        })
      }
      // eslint-disable-next-line camelcase
      if (this.previewFetchState.search_text !== searchText) {
        Object.assign(newFetchState, {
          search_text: searchText,
          page: 1,
          end: false,
          fetching: true
        })
      }
      const payload = {
        search_text: searchText,
        page_number: newFetchState.page,
        request_pretty_id: this.requestPrettyId
      }
      API.fetchPreviewUsers(payload)
        .then((response) => {
          const res = response.data
          let staffList = []
          if (res.length) {
            staffList = res.map((usr) => {
              return {
                value: usr.id,
                label: usr.name_with_email,
                role: usr.role
              }
            })
          }
          if (this.previewFetchState.search_text !== payload.search_text) {
            this.filteredPreviewUsers = []
            this.filteredPreviewUsers = staffList.concat(genericUsers)
          } else {
            this.filteredPreviewUsers = uniqBy(
              [...this.filteredPreviewUsers, ...staffList, ...genericUsers],
              'value'
            )
          }
          this.previewFetchState = Object.assign(newFetchState, {
            end: staffList.length < 25,
            fetching: false
          })
        })
        .catch(() => {
          this.filteredPreviewUsers = [].concat(genericUsers)
          this.previewFetchState = Object.assign(newFetchState, {
            fetching: false
          })
        })
    },
    loadMorePreviewUsers: throttle(function () {
      this.fetchPreviewUsers(this.previewFetchState.search_text)
    }, 400),

    resetPreviewModal() {
      this.filteredPreviewUsers = []
      this.permissionModal = false
      this.previewFetchState = {
        page: 0,
        search_text: '',
        end: false,
        fetching: false
      }
    },

    handleToggleMenu(): void {
      this.isMenuToggled = !this.isMenuToggled

      if (this.isMenuToggled) {
        this.$refs.menu.classList.add('is-active')
      } else {
        this.$refs.menu.classList.remove('is-active')
      }
    },

    handleSignoutClick(): void {
      this.$emit('on-sign-out')
    }
  }
}
