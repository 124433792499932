/* eslint-disable no-param-reassign */
export default {
  setAbility: (state, val) => {
    state.rules = val
  },

  setAccount: (state, obj) => {
    state.account = obj
  },

  setCurrentUser: (state, val) => {
    state.currentUser = val
  },

  setPreviewUser: (state, val) => {
    state.previewUser = val
  },

  setPreviewUserId: (state, val) => {
    state.previewUserId = val
  }
}
