import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "search-doc-container" }
const _hoisted_2 = { class: "nr-modal__title nr-modal__title--hidden-footer" }
const _hoisted_3 = { class: "modal-doc-text-field" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "no-more-results" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 2,
  class: "no-more-results"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NrFormInput = _resolveComponent("NrFormInput")!
  const _component_NrIconButton = _resolveComponent("NrIconButton")!
  const _component_DocumentCard = _resolveComponent("DocumentCard")!
  const _component_NrIcon = _resolveComponent("NrIcon")!
  const _component_InfiniteLoading = _resolveComponent("InfiniteLoading")!
  const _component_NrModal = _resolveComponent("NrModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NrFormInput, {
      name: "document-search",
      class: "search-doc-text-field",
      placeholder: "Search documents by keyword",
      hideLabel: "",
      label: "Search documents by keyword",
      onKeypress: _withKeys($options.submitFullTextSearch, ["enter"]),
      modelValue: $data.searchTerm,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.searchTerm) = $event))
    }, null, 8, ["onKeypress", "modelValue"]),
    _createVNode(_component_NrIconButton, {
      class: "search-doc-text-btn",
      variant: "bordered-neutral",
      "aria-label": "Search documents by text keyword",
      icon: { name: 'search', type: 'regular' },
      onClick: $options.submitFullTextSearch
    }, null, 8, ["onClick"]),
    _createVNode(_component_NrModal, {
      showClose: "",
      title: $data.modalHeaderTitle,
      show: $data.openDocListModal,
      showFooter: false,
      onOnCloseClick: _cache[2] || (_cache[2] = ($event: any) => ($data.openDocListModal = false))
    }, {
      header: _withCtx(() => [
        _createElementVNode("h2", _hoisted_2, _toDisplayString($data.modalHeaderTitle), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_NrFormInput, {
            name: "document-search",
            class: "search-doc-text-field",
            placeholder: "Search documents by keyword",
            hideLabel: "",
            label: "Search documents by keyword",
            onKeypress: _withKeys($options.submitFullTextSearch, ["enter"]),
            modelValue: $data.searchTerm,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.searchTerm) = $event))
          }, null, 8, ["onKeypress", "modelValue"]),
          _createVNode(_component_NrIconButton, {
            class: "search-doc-text-btn",
            variant: "bordered-primary",
            icon: { name: 'search', type: 'regular' },
            "aria-label": "Search documents by text keyword",
            onClick: $options.submitFullTextSearch
          }, null, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.filteredDocuments, (doc, index) => {
          return (_openBlock(), _createBlock(_component_DocumentCard, {
            key: `${doc.id}-item-${index}`,
            header: doc.created_at,
            title: doc.title,
            url: doc.document_path,
            visibility: doc.state,
            filetype: doc.file_extension
          }, {
            content: _withCtx(() => [
              _createElementVNode("span", {
                innerHTML: $options.sanitize(doc.highlights.content)
              }, null, 8, _hoisted_4)
            ]),
            _: 2
          }, 1032, ["header", "title", "url", "visibility", "filetype"]))
        }), 128)),
        _createVNode(_component_InfiniteLoading, {
          identifier: $data.infiniteId,
          onInfinite: $options.triggerInfiniteHandler
        }, {
          spinner: _withCtx(() => [
            _createVNode(_component_NrIcon, {
              name: "spinner",
              class: "full-text-search-loading-icon"
            })
          ]),
          complete: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              ($data.filteredDocuments.length && $data.modalSearchTerm.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, " All documents loaded matching your search term \"" + _toDisplayString($data.modalSearchTerm) + "\" ", 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_7, "No documents found matching your search term")),
              (!$data.modalSearchTerm.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, " All documents loaded "))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["identifier", "onInfinite"])
      ]),
      _: 1
    }, 8, ["title", "show"])
  ]))
}