import * as qs from 'qs'

interface IQueryParam {
  [key: string]: any
}

export const stringify = (param: IQueryParam = {}): string => {
  return qs.stringify(param, { arrayFormat: 'brackets' })
}

export const parse = (paramString: string): IQueryParam => {
  return qs.parse(paramString, { plainObjects: true, parseArrays: true })
}
