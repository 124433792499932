import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10f7ed4b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "document-search-result" }
const _hoisted_2 = { class: "document-search-result__header" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "document-search-result__body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString($props.header), 1),
      _createElementVNode("div", null, [
        ($props.filetype)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($props.filetype), 1))
          : _createCommentVNode("", true),
        ($props.visibility)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass([
            'document-search-result__visibility',
            `document-search-result__visibility--${$props.visibility}`
          ])
            }, _toDisplayString($options.setVisibilityText), 3))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("a", {
      href: $props.url,
      class: "document-search-result__title"
    }, _toDisplayString($props.title), 9, _hoisted_4),
    _createElementVNode("p", _hoisted_5, [
      _renderSlot(_ctx.$slots, "content", {}, undefined, true)
    ])
  ]))
}